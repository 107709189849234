.page-description {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1B1A55;
  padding: 20px 0;
}

.page-description h2 {
  font-family: "Merriweather", serif;
  font-weight: 700;
  color: white;

}

.base-single {
  text-align: left;
}

.input-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 10px;
  padding: 40px 25px;
}

.job-inputs {
  background-color: #1B1A55;
}

.filtered-jobs {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 20px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.filtered-jobs h3 {
  margin-bottom: 20px;
}

.job-content {
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
  border-bottom: 1px solid #e0e0e0;
  gap: 20px; /* Add space between logo, details, and button */
}

.job-details {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.job-type,
.job-location,
.job-title-company,
.job-posted-date {
  display: flex;
  flex-direction: column;
  width: 20%;
  text-align: left;
  gap: 0px;
}

.job-title-company span {
  font-weight: bold;
}

.job-type span,
.job-location span,
.job-title-company span,
.job-posted-date span {
  font-size: 20px;
  margin-bottom: 7px;
}

.job-type p,
.job-location p,
.job-title-company p,
.job-posted-date p {
  font-size: 14px;
  color: #838390;
  margin: 0px;
}

.job-apply {
  width: 20%;
}

.job-apply button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #1B1A55;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  border-radius: 20px;
  float: left;
}

.job-apply button:hover {
  background-color: rgba(0, 86, 179, 0.8);
}

.result-count {
  text-align: center;
  /* margin-left: 0; */
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; /* Top and bottom margin */
}

.pagination button,
.pagination span {
  margin: 0 5px;
  padding: 5px 10px;
}

.pagination button {
  border: none;
  background-color: #0073b1;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}


.skills-button .react-dropdown-select-content {
  border: none !important;
}

.skills-button .react-dropdown-select-input {
  color: #212529 !important;
}

.skills-button .react-dropdown-select-item {
  background-color: #fff !important;
  color: #212529 !important;
  padding: 0.25rem 0.5rem !important;
}

.skills-button .react-dropdown-select-dropdown {
  border: 1px solid #ced4da !important;
  border-radius: 4px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.skills-button .react-dropdown-select-clear,
.skills-button .react-dropdown-select-dropdown-handle {
  display: none !important; 
}

.skills-button .react-dropdown-select-selected-item {
  background-color: #e2e6ea !important;
  border-radius: 4px !important;
  margin: 0.25rem !important; 
}

.skills-button input[type="text"] {
  pointer-events: none; 
  cursor: default; 
}

.search-jobs button {
  width: 100%;
  background-color: #FF5733;
  border: none;
  height: 3rem;
  color: white;
  font-weight: bold;
  border-radius: 25px;
  cursor: pointer;
}

.loader-outer-layer {
  display: flex;
  justify-content: center;
  height: 200px;
  align-items: center;
}
.loader {
  width: 60px;
  aspect-ratio: 1;
  color: #ffa516;
  background:
    linear-gradient(currentColor 0 0) 100%  0,
    linear-gradient(currentColor 0 0) 0  100%;
  background-size: 50.1% 50.1%;
  background-repeat: no-repeat;
  animation:  l7-0 1s infinite steps(1);
}
.loader::before,
.loader::after {
  content:"";
  position: absolute;
  inset: 0 50% 50% 0;
  background: currentColor;
  transform: scale(var(--s,1)) perspective(150px) rotateY(0deg);
  transform-origin: bottom right; 
  animation: l7-1 .5s infinite linear alternate;
}
.loader::after {
  --s:-1,-1;
}
@keyframes l7-0 {
  0%  {transform: scaleX(1)  rotate(0deg)}
  50% {transform: scaleX(-1) rotate(-90deg)}
}
@keyframes l7-1 {
  49.99% {transform:scale(var(--s,1)) perspective(150px) rotateX(-90deg) ;filter:grayscale(0)}
  50%    {transform:scale(var(--s,1)) perspective(150px) rotateX(-90deg) ;filter:grayscale(0.8)}
  100%   {transform:scale(var(--s,1)) perspective(150px) rotateX(-180deg);filter:grayscale(0.8)}
}

.no-data-text {
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

#react-select-3-input {
  opacity: 1;
}

@media (max-width: 750px) {
  .job-details {
    display: flex;
    flex-direction: column;
  }

  .job-type,
  .job-location,
  .job-posted-date,
  .job-title-company,
  .job-apply  {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .job-apply button {
    width: 80%;
    float: none;
  }

  .input-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media (min-width: 751px) and   (max-width: 900px) {
  .input-container {
    grid-template-columns: repeat(1, 1fr);
  }

  .job-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .company-logo {
    margin-bottom: 10px;
  }

  .job-details,
  .job-apply {
    margin-left: 0;
  }

  .job-title-company {
    width: 30%;
    
  }
  .job-type,
  .job-location,
  .job-posted-date {
    width: 16.66%;
  }
}

