.page-description-aboutus {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #1b1a55;
  padding: 20px 0;
  height: 150px;
}

.page-description-aboutus h2 {
    font-family: "Merriweather", serif;
    font-weight: 700;
    color: white;
  }
