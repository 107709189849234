.get-in-touch {
    padding: 4rem 2rem;
    background-color: #f5f5f5; /* Light background color */
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    text-align: center;
  }
  
  h2 {
    font-size: 2.5rem;
    color: #333;
    margin-bottom: 1rem;
  }
  
  .contact-info {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
  }
  
  .info-item {
    display: flex; 
    align-items: center; 
  }
  
  .info-item img {
    width: 24px;
    height: 24px;
    margin-right: 1rem;
  }
  
  .info-item p {
    margin: 0;
  }
  
  .contact-form {
    background-color: white;
    padding: 50px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-form h3 {
    color: #333;
    margin-bottom: 1rem;
  }
  
  .form-row {
    display: flex; 
    gap: 1rem; 
  }
  
  .input-wrapper {
    position: relative; 
    flex: 1;
  }
  
  .contact-form input,
  .contact-form textarea {
    width: -webkit-fill-available; 
    padding: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: white; 
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .contact-form .input-wrapper.error input {
    border-color: red; 
  }
  
  .contact-form .error-icon {
    position: absolute;
    right: 0.8rem;
    top: 37%;
    transform: translateY(-50%);
    color: red;
    font-weight: bold;
    font-size: 1.5rem;
  }
  
  .contact-form input:focus,
  .contact-form textarea:focus {
    outline: none;
    border-color: #007bff; 
  }
  
  .contact-form textarea {
    height: 120px;
    resize: vertical;
  }
  
  .contact-form button {
    background-color: #007bff;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #0056b3; 
  }
  
  @media (max-width: 768px) {
    .contact-info {
      flex-direction: column;
      align-items: center;
    }
  
    .info-item {
      margin-bottom: 1rem;
    }
  
    .form-row {
      flex-direction: column;
    }
  }
  
  .loader-gif {
    position: fixed; /* Use fixed to center relative to the viewport */
    left: 50%;
    top: 50%;
    width: 30px;
    height: 30px;
    margin-left: -15px; /* Half of width to center */
    margin-top: -15px; /* Half of height to center */
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #3498db;
    animation: spin 2s linear infinite;
    z-index: 9999; /* Ensure it's on top of other elements */
}
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .dimming-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
    display: flex; 
    justify-content: center; 
    align-items: center;
    visibility: hidden; 
}

.dimming-overlay.active {
    visibility: visible; /* Show when active */
}
