.about-us {
    display: flex;
    align-items: center; 
    justify-content: space-between; 
    padding: 4rem 2rem;
    max-width: 1200px; 
    margin: 0 auto; 
}

.about-content {
    flex: 1; 
    padding-right: 2rem; 
}

.about-content h2 {
    color: #009688;
    margin-bottom: 1rem;
}

.about-content h3 {
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
    line-height: 1.2;
}

.about-content p {
    line-height: 1.6;
    margin-bottom: 2rem;
}

.call-to-action {
    background-color: #009688;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.about-image {
    flex: 1;
}

.about-image img {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px; 
}

@media (max-width: 768px) {
    .about-us {
        flex-direction: column; 
    }
    .about-content {
        padding-right: 0; 
        margin-bottom: 2rem;
    }
}
