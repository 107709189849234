.footer {
  background-color: #1a252f;
  color: white;
  padding: 40px 0;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.footer-column {
  margin: 0 15px;
}

.footer-column h3 {
  margin-bottom: 20px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column li {
  margin-bottom: 10px;
}

.footer-bottom {
  text-align: center;
  padding-top: 20px;
  border-top: 1px solid #333; 
}

@media (max-width: 768px) {
  .footer-container {
    grid-template-columns: 1fr;
    text-align: left;
    padding-left: 3rem;
  }

  .footer-column {
    margin: 15px 0;
  }
}

a {
  color: white;
}
