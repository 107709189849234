.hero {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 2rem;
  height: 100vh;
  background-color: #1B1A55;
}

.hero-image {
  height: 100%;
}

.hero-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}


.hero-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-left: 2rem;
  color: white;
  }



@keyframes slide-in-bottom {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.fade-in-text {
  opacity: 0;
  animation: slide-in-bottom 1s forwards;
}

.hero h1 {
  font-size: 2rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.5rem;
  margin-bottom: 1.5rem;
}

.hero button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  background-color: #535C91;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  width: fit-content;
}

.hero button:hover {
  background-color: #0056b3;
}

@media (max-width: 1024px) {
  .hero {
    display: flex;
    flex-direction: column-reverse;
  }

  .hero-content {
    height: auto;
    padding-left: 0;
    margin-bottom: 2rem;
    text-align: center;
  }

  .hero button {
    align-self: center;
  }

}