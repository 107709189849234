.full-width-background {
  background-color: #f3f6f6;
}

.what-we-serve {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  gap: 2rem;
  align-items: center;
}

.service-items {
  display: grid;
  grid-template-columns: repeat(2, 200px);
  gap: 2rem;
  align-items: stretch; /* Stretch items to fill the row */
  transition: transform 1s ease; /* Add transition */
  transform: translateX(-20px); /* Initial position */
}

.service-items.visible {
  transform: translateX(0); /* Final position when visible */
}

.service-item {
  padding: 2rem;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease; /* Add transition */
  border: none;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  margin: 0;
}

.service-item:nth-child(odd) {
  /* Odd items (1st, 3rd, etc.) */
  margin-top: 2rem;
}

.service-item:nth-child(even) {
  /* Even items (2nd, 4th, etc.) */
  margin-bottom: 2rem;
}

.service-item:nth-child(2),
.service-item:nth-child(3) {
  background-color: #eff8ff;
}

.service-item:nth-child(1),
.service-item:nth-child(4) {
  background-color: #ffffff;
}

.service-item:hover {
  transform: translateY(-5px);
}

.service-icon {
  width: 50px;
  height: 50px;
}

.service-title {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.service-description {
  color: #666;
}

.description-section {
  text-align: left;
  transition: transform 1s ease; /* Add transition */
  transform: translateX(20px); /* Initial position */
}

.description-section.visible {
  transform: translateX(0); /* Final position when visible */
}

.description-title {
  color: #009688;
  margin-bottom: 1rem;
}

.description-heading {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  line-height: 1.2;
}

.description-text {
  color: #555;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.get-started-button {
  background-color: #009688;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

/* Media Query for responsiveness */
@media (max-width: 800px) {
  .what-we-serve {
    flex-direction: column-reverse;
  }

  .service-items {
    grid-template-columns: 1fr;
  }

  .service-item {
    border-right: none;
  }

  .service-item:nth-child(odd),
  .service-item:nth-child(even) {
    margin-top: 0;
    margin-bottom: 0;
  }
}
